.footer {
  border-top: 1px solid black;
  width: 100%;
  background-color: rgb(50, 50, 50);

  .icons {
    margin-bottom: 0;
  }

  ul {
    display: flex;
    justify-content: center;
    padding: 2%;
    li {
      padding: 2%;
      color: white;
      list-style-type: none;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
