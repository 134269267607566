.navbar {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 60px;
  background-color: rgb(50, 50, 50);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 5px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 600px) {
    height: 60px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    margin: 0 20px;
    color: white;

    @media (max-width: 400px) {
      margin: 0 10px;
      font-size: 0.9rem;
      padding: 0 6px;
    }
  }
  z-index: 1;
}

.navbar-left {
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1200px) {
    width: 30%;
  }
  @media (max-width: 850px) {
    width: 40%;
  }
  @media (max-width: 600px) {
    width: 55%;
    padding: 0;
  }

  @media (max-width: 400px) {
    width: 55%;
  }

  .logo-img {
    width: 55px;
  }
}
.navbar-middle {
  width: 59%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    width: 39%;
  }
  @media (max-width: 850px) {
    width: 19%;
  }
  @media (max-width: 600px) {
    display: none;
  }
}

.navbar-right {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1200px) {
    width: 30%;
  }
  @media (max-width: 850px) {
    width: 40%;
  }
  @media (max-width: 600px) {
    width: 44%;
    padding: 0;
  }
  @media (max-width: 400px) {
    width: 44%;
  }
}

.not-authed {
  justify-content: flex-end;
}

.logo {
  font-size: 1rem;
}
