.search {
  input {
    padding: 17px 10px;
    border-radius: 5px;
    border: 0.5px solid rgb(50, 50, 50);
  }
}

.search-input {
  width: 90%;
  height: 25px;
}

#main-button-search {
  color: white;
  background-color: #e1887b;
  margin: 20px 0 5px 0;
}
