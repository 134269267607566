.container {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 800px) {
    text-align: center;
    padding: 0 20px;
  }

  .authentication-form {
    width: 350px;
    height: 400px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
  }

  h2 {
    align-self: center;
    margin-bottom: 10px;
    font-size: 2.5rem;
  }

  input {
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid black;
    line-height: 1.5;
    width: 100%;
    &::placeholder {
      font-size: 1rem;
      color: gray;
      text-align: center;
    }
    &:focus {
      outline: none;
    }
  }

  #main-button {
    color: white;
    background-color: #e1887b;
    margin: 10px 0;
    width: 100%;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }

  #logout-button {
    color: white;
    background-color: #e1887b;
    margin: 10px 0;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }

  .info-text {
    color: #ef8376;
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .redirect-text {
    color: #ef8376;
    font-size: 1rem;
    text-decoration: none;
    margin-right: 10px;
  }
}

.error {
  color: #e1887b;
}
