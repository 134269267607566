.user-page {
  height: 100vh;
  width: 100vw;
  padding-top: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .emphasize {
    color: #ef8376;
  }

  .user-form {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  input {
    margin: 5px 10px 5px 10px;
    padding: 7px 10px;
    border-radius: 5px;
    border: 1px solid black;
    &::placeholder {
      font-size: 1rem;
      color: gray;
    }
    &:focus {
      outline: none;
    }
  }

  h2 {
    margin: 0;
  }

  #main-button {
    color: white;
    background-color: #e1887b;
    margin: 10px;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }
}
