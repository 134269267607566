// import '../keyframes.scss';
.hacker-list {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  // .scale-up-top();
}

.not-found {
  color: #e1887b;
}
.hacker-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.hacker-container {
  width: 70%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1200px) {
    width: 80%;
  }

  @media only screen and (max-width: 1000px) {
    width: 95%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.load-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://media.giphy.com/media/l0He7tLZz0qI6EpuU/giphy.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: contain;
  min-height: 80vh;
  z-index: 99;
}

#main-button {
  color: white;
  background-color: #e1887b;
  margin: 5px 10px;
  line-height: 1.1;
}
