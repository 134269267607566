.full-screen-div {
  min-height: 90vh;
  margin-top: 15vh;
  font-size: 1.5rem;
  color: #ef8376;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 1.8;
}
.no-match-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 1.8;
  text-align: left;
  padding-right: 20%;
  margin: 0;

  @media only screen and (max-width: 1000px) {
    padding: 0 10%;
  }

  @media only screen and (min-width: 1700px) {
    padding-left: 5%;
    padding-right: 0;
  }

  @media only screen and (min-width: 1900px) {
    padding-left: 10%;
  }

  @media only screen and (min-width: 2100px) {
    padding-left: 20%;
  }
}

.img-404 {
  display: none;
}
