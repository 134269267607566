body {
  background-color: #eaeaea;
}

.app-wrapper {
  text-align: center;
  margin: 90px auto 10px 20%;
  max-width: 1300px;

  @media only screen and (max-width: 1000px) {
    margin: 70px auto 10px auto;
  }
}
