.sidebar {
  width: 20%;
  height: 100vh;
  background-color: rgba(50, 50, 50, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;

  overflow-x: hidden;
  padding-top: 20px;

  @media (max-width: 1200px) {
    width: 25%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
  .data-key {
    border-bottom: solid white 1px;
    padding: 0px 10px 20px 10px;
    line-height: 1.2;
    font-weight: 300;
  }

  h3 {
    line-height: 1.5;
    font-weight: 300;
  }

  #main-button {
    color: white;
    background-color: #e1887b;
    margin: 10px 0;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }

  .random-comment {
    margin-top: 30px;
    font-style: italic;
    font-size: 1.1rem;
  }
}
