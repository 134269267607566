.hacker {
  display: flex;
  width: 100%;
}
.hacker a {
  margin: 20px 0;
  width: 100%;
  background-color: white;
  color: black;
  padding-top: 40px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 850px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.hacker-text {
  padding-left: 80px;
  @media (max-width: 1200px) {
    padding-left: 80px;
  }

  @media (max-width: 1100px) {
    padding-left: 60px;
  }

  @media (max-width: 1000px) {
    padding-left: 80px;
  }

  @media (max-width: 850px) {
    padding-left: 0px;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 1.4rem;
    padding: 5px 0;
    color: rgb(50, 50, 50);
  }
  .bold {
    font-weight: bold;
  }
}

.relative-position {
  position: relative;
  cursor: pointer;
}

.info-list {
  top: 40px;
  left: 25px;
  position: absolute;
}
.index-card-list {
  position: absolute;
  width: 150px;
  height: auto;
  padding: 10px 5px 10px 10px;
  border-radius: 5px;
  top: 20px;
  left: 50px;
  color: rgb(50, 50, 50);
  background-color: #ef8376;
  font-size: 0.7rem;
  text-align: start;
  font-style: italic;
}
.emphasis {
  color: white;
  font-weight: bold;
}
