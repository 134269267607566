.hacker-profile {
  display: flex;
  justify-content: space-between;
  background-color: #eaeaea;
  min-height: 95vh;
}

.hacker-column {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    width: 80%;
  }

  @media only screen and (max-width: 1000px) {
    width: 90%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.details {
  margin: 20px;
  padding: 30px 0 0 70px;
  display: flex;
  min-height: 260px;
  background: #ffff;
  color: black;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 850px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px 0px 40px;
    margin: 20px 0;
  }
}

.details-left-box {
  text-align: center;
  margin-top: -30px;
  @media (max-width: 1200px) {
    margin-top: 10px;
    padding-left: 30px;
    margin-bottom: 20px;
  }

  @media (max-width: 1100px) {
    padding-left: 5px;
  }

  @media (max-width: 850px) {
    padding: 0 15px;
    margin-bottom: 15px;
  }
}
.icon {
  width: 22%;
  font-size: 30px;
  border-radius: 28px;
}

.relative-position {
  position: relative;
}
.index-card-comment {
  position: absolute;
  width: 150px;
  height: auto;
  padding: 10px 5px 10px 10px;
  border-radius: 5px;
  top: 0px;
  left: 50px;
  color: rgb(50, 50, 50);
  background-color: #ef8376;
  font-size: 0.7rem;
  text-align: start;
  font-style: italic;
  .emphasis {
    font-weight: 600;
    color: white;
  }
}

.bold {
  font-weight: bold;
}

.error-message {
  height: 100vh;
  color: #ef8376;
  font-weight: bold;
  margin-top: 20%;
}

.info-comment {
  top: 20px;
  left: 25px;
  position: absolute;
}

.not-found-message {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 1.8;
  font-size: 1.5rem;
  color: #ef8376;
}
